.patch-img {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 40vw;
  @media only screen and (min-width: $desktop) {
    width: 425px;
  }
  @media only screen and (max-width: $tablet) {
    width: 280px;
    top: 0;
    transform: translateX(50%);
    right: 50%;
  }
}
.pcb-img {
  animation: fadein 6s infinite;
  @media only screen and (max-width: $tablet) {
    width: 280px;
    margin: auto;
    display: block;
  }
}
@keyframes fadein {
  0%,100% { opacity: 0 }
  60% { opacity: 1 }
}
.science-img {
  @media only screen and (min-width: $tablet) {
    float: right;
  }
}
.pin-container {
  background: white;
  box-shadow: 0 2px 10px -4px black;
  position: relative;
  @media only screen and (min-width: $tablet) {
   // position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.pin-img {
  position: absolute;
  width: 40px;
  right: 2rem;
  top: -2rem;
}
.zoom-img {
  position: absolute;
  right: 22%;
  transform: translate(50%, 50%);
}
.screens-img {
  width: auto;
  @media only screen and (min-width: $tablet) {
    height: 27vw;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @media only screen and (max-width: $tablet) {
    position: unset;
    height: 58vw;
    display: block;
    // margin: auto;
  }
  @media only screen and (min-width: $desktop) {
    height: 270px;
  }
}
.item-img {
  border-radius: 50%;
  max-width: 40%!important;
}

.use-img {
  height: auto;
  max-height: 525px;
  @media only screen and (max-width: 500px) {
    width: 100vw;
    max-width: 100vw;
    margin-left: -16px;
    height: auto;
    max-height: max-content;
  }
  @media only screen and (min-width: 500px) and (max-width: $tablet) {
    width: 60vw;
    margin: auto;
    display: flex;
  }
}

