.banner-img-business {
  width: 100vw;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-image: url('../_images/business/header_business.jpg');
  @media only screen and (max-width: $tablet) {
    height: 300px;
    background-image: url('../_images/business/header_business.jpg');
  }
}

.img-container-business {
  transform: translateY(15px);
  @media only screen and (max-width: $tablet) {
    width: 35vh;
    margin: auto;
  }
}
.chart-container-business {
  width: 90%;
  bottom: 39%;
  height: 39%;
  position: absolute;
  left: 9%;
  @media only screen and (max-width: $tablet) {
    bottom: 63%;
  }
}
.full-height {
  height: 100%;
}
.icon-business {
  height: 110px;
  margin-right: 2rem;
  @media only screen and (max-width: $tablet) {
    margin: 0 auto 2rem auto;
    display: block;
  }
}
.description-width {
  @media only screen and (min-width: $tablet) {
    width: 70%;
  }
}
.patches-img {
  width: 30vw;
  margin-right: 20px;
  @media only screen and (min-width: $desktop) {
    width: 260px;
    margin-right: 70px;
  }
}
.devices-business {
  width: 43vw;
  @media only screen and (min-width: $desktop) {
    width: 380px;
  }
}
