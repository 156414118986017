.section-bgimg-private {
  background-position: 80%;
  background-size: cover;
  background-image: url('../_images/private/boy+patch_dark+white-bottom.jpg');

  @media only screen and (max-width: $tablet) {
    background-position: right;
    background-image: url('../_images/private/pics/istock-mobile-neu.png');
  }
}

.title_home-private {
  // text-align: right;
  width: 50%;
  // margin: auto;
  margin-left: 45%;
  padding-bottom:2rem;
  h1 {
    font-size: 5vw!important;
    text-align:center;
    @media only screen and (min-width: $tablet) {
      text-align: right;
    }

  }
  @media only screen and (min-width: $widescreen) {
    //width:668px;
    //margin-left:473px;
    h1 {
      font-size: 48px!important;
    }
  }
  @media only screen and (max-width: 500px) {
    // padding:0 1rem 1rem 0.5rem;
    margin:auto;
    text-align:center;
    width:80%;
    color: black !important;
    h1 {
      font-size:6.5vw!important;
      font-weight:400;
      color: black !important;
    }
    p {
      color: black !important;
    }
  }
}

.icons {
  text-align: center;
  justify-content: center;
  display: block;
  @media only screen and (max-width: &mobile) {
    text-align: left;
    display: flex;
  }
}
