.title,
.has-text-pink-gradient {
  // background-image: linear-gradient(to right bottom, #00cbd6, #00d7cb, #3ce2b9, #6beba2, #99f188);

  @include text-gradient($gradient-left-color, $gradient-right-color2, 80%);
}

.title.has-text-dark {
  @include text-gradient-reset();
}
.title.has-text-grey {
  @include text-gradient-reset();
}

.title.has-text-white {
  @include text-gradient-reset();
}

.has-text-green-gradient {
  background-image: linear-gradient(to right bottom, #00cbd6, #00d7cb, #3ce2b9, #6beba2, #99f188);
}

.title.is-4,
.title.is-5,
.title.is-6,
.title.is-7 {
  font-weight: 300;
}

@media (max-width: $desktop) {
  .title.is-1 {
    font-size: $size-4;
  }

  .title.is-2 {
    font-size: $size-3 * 0.8; // 2rem
  }

  .title,
  .title.is-3 {
    font-size: $size-3 * 0.6; // 1.5rem
  }

  .title.is-4 {
    font-size: $size-5;
  }

  .title.is-5 {
    font-size: $size-5 * 0.9; // 1.125rem
  }

  .subtitle.is-1 {
    font-size: $size-4;
  }

  .subtitle.is-2 {
    font-size: $size-4;
  }

  .subtitle.is-3 {
    font-size: $size-5;
  }

  .subtitle.is-4 {
    font-size: $size-5;
  }

  .subtitle,
  .subtitle.is-5 {
    font-size: $size-6;
  }

  .subtitle.is-6 {
    font-size: $size-6;
  }
}

.page__content,
.post__content {
  h1, h2, h3, h4, h5, h6 {
    @extend .title;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  h1,
  h2 {
    @extend .is-4;
  }

  h3,
  h4,
  h5,
  h6 {
    @extend .is-5;
  }

  h1, h2, h3, h4, h5, h6, .title, .subtitle {
    word-break: normal !important;
  }
}
