.article {
  &:nth-child(odd) {
    background-color: $block-primary-color !important;
  }

  &:nth-child(even) {
    background-color: $block-secondary-color !important;
  }
}

.blog__post {
  overflow: auto; // clear floats
  padding-top: 50px;
  padding-bottom: 50px;
  background: linear-gradient(90deg, rgba(190, 75, 145, 0.3), rgba(243, 152, 153, 0.3)) no-repeat left bottom;
  background-size: 100% 1px;

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    background: none;
  }
}

.blog-1 {
  @media only screen and (max-width: $tablet){
    margin-top:2rem;
  }

}

.blog__img {
  float: left;
  width: 6rem;

  @media only screen and (min-width: $tablet) {
    width: 10rem;
  }
}

.blog__link {
  &:hover {
    color: $link;
  }
}

.blog__title {
  color: $text;
  font-size: $size-5;
  padding-top: 0;
}

.blog__excerpt {
  color: $text;
}
