.details-partners{
  cursor: pointer;
}

.hr-partners {
  background: linear-gradient(to right, $gradient-left-color, $gradient-right-color2);
  height: 2px;
  width: 12%;

  @media only screen and (max-width: 500px) {
    margin: auto;
    margin-bottom: 0.5rem;
  }
}

.hr-partners-small{
  background: linear-gradient(to right, $gradient-left-color, $gradient-right-color2);
  width: 12%;

  @media only screen and (max-width: 500px) {
    margin: auto;
    margin-bottom: 1rem;
  }
}

summary:hover {
  color: #008fdf;
}

summary:focus {
  color: #008fdf;
  font-weight: bolder;
}

.partner-contact{
  color: #333333;
  text-decoration: underline;
}

.coming-soon{
  font-weight: bolder;
  text-transform: uppercase;
}

.padding-top-correction{
  padding-top: 2rem;
}

.grid-container {
  display:grid;
  grid-template-columns: auto;
  @media only screen and (min-width: 500px) {
    grid-template-columns: auto auto;
  }
}

.grid-item {

}
