@import "variables";

.banner-top-sticky {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
  color: $text-invert;
  height: $banner-top-sticky-height;
  font-size:1.25rem;
  background: linear-gradient(90deg, $gradient-left-color,$gradient-left-color2, $gradient-right-color2);
  > * {
    margin-right: calc($gap / 4);
  }
  .button {
    border-width: 2px;
  }
}
.banner-top-sticky:hover {
  color: $text-invert;
}

header {
  position: fixed;
  right: 0;
  left:0;
  top: 0;
  background-color: $navbar-background-color;
  z-index: $navbar-z;
}

