// Styling for the newsletter section

.modal:target {
  visibility: visible;
  display: flex;
  opacity: 1;
}

div#mc_embed_signup {
  background:#fff;
  clear:left;
  .mc-field-group {
    width: 80%;
    margin: auto;
  }
}
div.mce_inline_error {
  padding-bottom: 0!important;
  margin-bottom: 0!important;
}
.response {
  font-size: 0.8rem!important;
  width: auto!important;
  padding-top: 0!important;
  margin-top: 0!important;
}
form#mc-embedded-subscribe-form {
  display: inline;
  width: auto;
  margin-right: 10px;
  .mc_fieldset {
    border:none;
    min-height: 0px;
    padding-bottom:0px;
  }
}
.subscribe-banner {
  @media only screen and (max-width: 500px) {
    visibility:hidden;
    position: absolute;
  }
}
.newsletter-header {
  font-size: 1.2rem;
}
/**
.modal-content {
  background-color:#fff;
}
.m-content {
  width: 600px!important;
  height: 700px!important;
  background-color:#fff;
  @media only screen and (max-width: 600px) {
    width: 100%!important;
    height: 650px!important;
  }
}
*/
.m-content_us {
  @media only screen and (max-width: 600px) {
    height:450px!important;
  }
}
.modal-btn {
  width: 600px;
  height: 35px;
  position: relative;
  @media only screen and (max-width: 600px) {
    width: 100%!important;
  }
}
.modal-close {
  position: absolute;
  right: 0;
  top: 0;
}
.modalContent__image {
  @media only screen and (min-width: 500px) {
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.modal-title-bg {
  color: black;
  height:7rem;
  padding-bottom: 1rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
/*
.modal-title-bg::before {
  background-size: cover;
  background-image: url('/assets/images/professional/steadytemp_professional_header.jpg');
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0.75;
  content: "";
}
.modal-title-text {
  position: relative;
  text-align: center;

}
*/

.modal-title-bg {
  position: relative;
  height:7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../_images/professional/steadytemp_professional_header.jpg');
  background-size: cover;
}

.modal-title-bg::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255,255,255,0.5);
}

.modal-title-text {
  position: relative;
  text-align: center;
  padding:2rem 1rem;

}



.email-modal{
  font-weight: 600!important;
  font-size: 0.9rem!important;
  color: black;
  padding-bottom: 10px;
}
.button-subscribe{
  background-color: $primary!important;
}
.success-message {
  color: green;
  font-style: italic;
  margin-bottom: 1em;
  font-size:90%;
}
.error-message {
  color: red;
  font-style: italic;
  margin-bottom: 1em;
  font-size: 90%
}
.nl-columns {
  margin: 0!important;
  div {
    padding-bottom: 0.3rem;
  }
}
.flex-col {
  flex-direction: column;
}
.textfield {
  width:85%;
  @media only screen and (max-width:500px) {
    width:100%
  }
}
.nl-left {
  text-align: right;
  div {
    padding-bottom:0.3rem;
  }
}
.nl-submit-btn {
  margin:auto;
  text-align: center;
}
.nl-text {
  width:350px;
  text-align: left;
  margin:auto;
}

.modal-container {
  padding-bottom: 2rem;
}

.contact-us-success,.contact-us-error {
  padding-top:2rem;
  white-space: pre;
}

.text-header {
  opacity: 100%!important;
  z-index: 10;
  text-shadow: 2px 2px 25px #000;
  color: #fff;
  padding: 2rem 1rem;
}
#contact-us-form {
  padding-top:2rem;
}
