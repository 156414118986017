/*
femSense CSS
2018 by SteadySense
 */

// Fonts
@font-face {
    font-family: 'Basetica';
    src: url('../_src/fonts/Basetica-Light.woff2') format('woff2'),
        url('../_src/fonts/Basetica-Light.woff') format('woff'),
        url('../_src/fonts/Basetica-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basetica';
    src: url('../_src/fonts/Basetica-Medium.woff2') format('woff2'),
        url('../_src/fonts/Basetica-Medium.woff') format('woff'),
        url('../_src/fonts/Basetica-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// Mixin

@mixin text-gradient($from, $to, $topercent) {
  color: $primary;
  background: -webkit-linear-gradient(0, $from, $to $topercent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.2em; // fix cut off descenders
}

@mixin text-gradient-reset() {
  background: none;
  -webkit-text-fill-color: inherit;
  padding-bottom: 0;
}

// Global

html {
  scroll-behavior: smooth;
}
body {
  -webkit-overflow-scrolling: touch; //safari scroll problem fix
  font-family: $primary-font-family;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
  line-height: 1.5;
  color: $text-color;
  background-color: $block-primary-color;
  display: flex;
  height: 100vh;
  flex-direction: column;
  //overflow-x: hidden;
  position:relative;
}

main {
  width: 100%;
  margin-top: 2.5rem;
}
a {
  color: $text-color;
  text-decoration: none;

  &:hover {
    //color: $text-highlight-color;
  }
}
p, ul, ol {
  margin-bottom: 1em;
}
::selection {
  background: $gradient-left-color;
  color: $block-primary-color;
}
::-moz-selection {
  background: $gradient-left-color;
  color: $block-primary-color;

}

blockquote {
  margin: 2rem 1rem;
  font-size: 1.125rem;
  font-weight: normal;

  cite {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
  }

  @media only screen and (min-width: $tablet) {
    margin: 3rem 3rem;
    font-size: 1.5rem;
  }
}

cite {
  font-style: normal;
  color: $text-light-color;
}

// Shared

.accent {
  color: $primary;
}

.a50 {
  color: rgba(51,51,51,0.50);
}

.o37 {
  opacity: 0.37;
}

.border-top {
  border-top:1px solid #e5e5e5;
}
.border-top-dark {
  border-top:1px solid #A0A0A066;
}
.border-bottom {
  border-bottom:1px solid #e5e5e5;
}
// Components & pages

.footer {
  padding: 0 1rem;

  &__first {

    padding: 25px 0;

  }

  &__lang {

    @media only screen and (max-width: $tablet) {
      padding: 0 22px 0 40px;
    }

  }

  &__menu {

    &__title {

      font-weight: 500;
      font-size: $size-6;
      color: $text-color-2;
      padding-bottom:10px;
      user-select: none;
      @media only screen and (max-width: $tablet) {
        padding-left: 1rem;
      }

      .footer__menu__payments {

        hyphens: auto;

      }

    }

    &__links, &__links a {

      color: rgba(64,64,64,0.6);
      font-size: $size-7;
      letter-spacing: 1px;
      line-height: 25px;
      text-decoration: none !important;

      transition: $transition-time;

      .ifu {

        hyphens: auto;

      }

    }

    &__links {
      margin-bottom: 0;
    }

    &__links a:hover {

      color: $text-color;

    }

  }

  &__copyright {
    opacity: 0.8;
    font-size: $size-7;
    color: $text-color-3;
    vertical-align: super;
  }

  &__payments__image {
    background: white;
    width: auto;
    height: 1.75rem;

    &--border {
      border-radius: 3px;
      border: 2px solid #e4e4e4;
    }
  }
}
.footer__social {
  @media screen and (max-width: $tablet) {
    margin-top: 20px !important;
  }
  &__image {
    height: 20px;
    width: auto;
    padding: 0 5px;
    @media screen and (max-width: $tablet) {
      padding: 0 17px;
    }
  }
}
.footer_border:before {
  content: "";
  background: #dedbdb;
  position: absolute;
  height: 1px;
  width: 100%;
  margin-top: -1rem;
  @media only screen and (max-width: 768px) {
    visibility: hidden;
  }
}



// Additional Classes

// Accordion
.houdini-toggle {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  outline: none !important;
  width: 100%;
  letter-spacing: 1px;
  text-align: left;
  font-family: inherit;
  font-weight: inherit;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0 0 0;
}
.accordion,
.houdini-toggle {
  cursor: pointer;
}
.houdini-toggle-icon {
  height: 100%;
  display: block;
  margin-left: 0.75em;

  font-weight: 200;
  font-size: 1.5rem;
  color: $primary !important;
}

// Footer Menu
.footer__menu-mobile {
  margin: 0 -1rem;

  .houdini-toggle {
    @extend .footer__menu__title;
    padding-left: 0;
    padding-bottom: 0;
  }

  .houdini-toggle-icon {
    margin-right: 1rem;
  }

  a {
    display: block;
    padding: 0.75rem 0;
    margin-bottom: 0.25rem;
  }
}

.footer__menu-mobile .accordion-content {
  padding: 0 1.25em 1em 2.25em !important;
}

// Cookie Consent overwrite
.cc-window {
  z-index: 2000000001;  // To be above tawk.io which defaults to 2000000000
}
@media screen and (max-width: $tablet) {
  #cookieconsent\:desc {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .cc-window {
    padding: 18px;
    z-index: 9999;  // Reset tawk.io override
  }
  .cc-link {
    font-size: 14px;
  }
}

// Houdini Accordion
.houdini.is-expanded {
  display: block;
}

.banner {
  text-align: center;
  color: $block-secondary-color;
  margin-bottom: 0.5rem;
  height: 2.25rem;
  font-size:1.25rem;
  padding-top: 0.3rem;
  background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);

  @media only screen and (min-width: $desktop) {
    padding-top: 0.5rem;
    height: 3.0rem;
    font-size: 1.5rem;
  }
}

.ifu-link {
  margin:1rem 1rem 3rem 1rem;
  li {
    margin-bottom: 1rem;
  }
}

.ifu-text {
  padding-left: 1rem;
}

// button definition
.btn_white {
  display:flex;
  float:right;
  @media only screen and (max-width: 500px){
    margin:auto;
    float: none;
  }
}

.footer_new {
  text-align: center;
  padding-top: 10px;
  font-size: 0.875rem;
  color: rgba(64,64,64,.6);

  .footer_headline {
    padding-top: 20px;
    text-transform: uppercase;
  }

  .gotop {
    text-transform: uppercase;
    text-align: right;
    padding-right: 10px;
    font-weight: 800;
  }

}

.font-correction{
  font-size: 0.875rem;
  color: rgba(64, 64, 64, 0.6);
}

.ma {
  margin:auto!important;
}

img.has-round-borders {
  border-radius: 25px;
}

.carousel-text {
  width: 86%;
  margin-left: 7%;
  font-size: 0.9rem;
}

.carousel-item {
  margin:auto auto 4rem auto ;
  img {
    width:80%;
  }
}

.text__appstore {
  min-width: 8rem;
  letter-spacing: 0.5px;
}
.appstore-download {
  margin-bottom: 5px;
  width: 8rem;
  height: auto;

  @media only screen and (min-width: $tablet) {
    width: auto;
    height: 2.625rem;
  }
}
.appstores {
  padding-top:0!important;
  padding-bottom: 10px!important;
}
.appstores:before {
  content: "";
  background: #dedbdb;
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
}

.section_contact {
  text-align: center;
  color: $block-secondary-color;
  background: linear-gradient(90deg, $gradient-left-color,$gradient-left-color, $gradient-right-color2);

  cursor: pointer;
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;
  a {
    color: $block-secondary-color;
  }
  .button:hover {
    color: $primary;
  }
}

.header-margin{
  margin-top: 3rem;
}

.button {
  &.is-customcolor {
    color: white;
    border-color: transparent;
    background-color: $text-color;
    &.is-outlined {
      color: $text_color;
      border-color: $text-color;
      background-color: transparent;
      @media only screen and (min-width: $tablet) {
        color: white;
        border-color:white;
      }
    }
  }
}
.has-text-customcolor {
  color: $text-color!important;
  @media only screen and (min-width: $tablet) {
    color: white!important;
  }
}
