@import 'variables';

.navbar {
  max-width: $desktop;
  margin: auto;
  filter: invert(0%);
  transition: all $navbar-animation-duration;
  color:$text-color;
  .navbar-right {
    cursor: pointer;
    display: flex;
    z-index: 10;
  }
}

.locale-dropdown {
  color: $text-color;
  &:hover,
  &.is-active {
    > .dropdown-menu {
      box-shadow: 0.25rem 0.25rem 0.25rem rgba(0,0,0,0.1);
      display: block;
      min-width: initial;
    }
  }
}

.navbar-item.is-active{
  font-weight:400;
}
a.navbar-item:hover {
  color: $text-color;
}
.navbar-item {
  color:$text-color;
}

button.is-text{
  text-decoration: none!important;
}

@media screen and (max-width: $desktop) {
  .navbar-item {
    color: $text-invert;
  }
  .navbar-brand{
    justify-content: space-between;
  }
  .navbar-end {
    display: none;
  }
  .navbar-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    background: $gradient-1;
    padding-top: $navbar-height;
    display: block;
    opacity: 0;
    left: 100%;
    transition: all $navbar-animation-duration;
    .navbar-item {
      font-size: 1.25rem;
      opacity: 0;
      left: 20%;
      transition: all $navbar-animation-duration;
    }
  }

  .navbar.is-active {
    .navbar-brand {
      filter: invert(100%);
      transition: all $navbar-animation-duration;
    }
    .navbar-menu {
      opacity: 1;
      left: 0;
      transition: all $navbar-animation-duration;
      .navbar-item {
        opacity: 1;
        left: 0;
        transition: all $navbar-animation-duration;

        @for $i from 1 through 10 {
          &:nth-of-type(#{$i}) {
            transition-delay: $navbar-item-animation-delay * $i;
          }
        }
      }
    }
  }
}
