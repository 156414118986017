.section {
  background-color: $block-primary-color;
}
.sectionwhite {
  background-color: $block-secondary-color;
}

.container--wide {
  max-width: $widescreen;
  width: 100%;
  margin-left:auto;
  margin-right:auto;
}

.container--narrow {
  max-width: 800px;
}
