.faq {

  h2 {
    color: $gradient-left-color2;
    -webkit-text-fill-color: $gradient-left-color;
  }

  .houdini-toggle {
    background: transparent;
    border: 0;
    outline: none !important;
    width: 100%;
    letter-spacing: 1px;
    text-align: left;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0 0 0;
  }

  .houdini-toggle-icon {
    height: 100%;
    display: block;
    margin-left: 0.75em;

    font-weight: 200;
    font-size: 1.5rem;
    color: $primary !important;
  }

  .houdini.is-expanded {
    display: block;
  }

  h2, h3, h4, h5, h6 {
    margin-top: 0 !important;
    margin-bottom: 0.5em !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    letter-spacing: 1px;
    font-weight: 300;
  }

  .accordion-content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .question-anchor {
    padding: 0 0.3em;
  }

  @media screen and (min-width: $tablet) {
    h4, .accordion-content { padding-left: 0.3rem; }
  }
  @media screen and (min-width: $desktop) {
    h4, .accordion-content { padding-left: 0.8rem; }
  }

  .is-active {
    height: auto !important;
  }

  p, li, a, code {
    font-size: 1.15rem;
    @media screen and (max-width: $tablet) {
      font-size: 1rem;
    }
  }

  p {
    padding-top: 0.5em;
    @media screen and (max-width: $tablet) {
      padding-right:0px;
      font-size: 1rem;
    }
  }
}

.faqvid {

  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}
.faqsupporttime {
  font-size: 14px;
  @media screen and (max-width: $tablet) {
    margin-top: 5px!important;
    margin-bottom: 20px!important;
  }
}
.faqvideo {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: $tablet) {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // IE10-11 fix
      height: 277px;
    }
    @supports (-ms-ime-align: auto) {
      // MS Edge
      height: 277px;
    }
  }

  img {
    cursor: pointer;
  }

}
.vidimg {
  @media screen and (min-width: $desktop) {
    width: 100%;
  }

}

.faq-container{
  padding: 0.75rem;
}
