@import 'variables';

.nl-text .textfield {
  width: 100%;
}

.m-content {
  width: 600px!important;
  @media only screen and (max-width: 600px) {
    width: 100%!important;
  }
}
.contact-logo {
  height: auto;
  width: 40%;
  margin: auto;
  display: block;
  padding-bottom: 1rem;
  @media only screen and (max-width: 600px) {
    width: 60%;
  }
}
.heading-modal {
  font-size: $size-6 !important;
  text-align: center;
}
.subtitle-modal {
  font-weight: 600 !important;
  font-size: $size-6 !important;
  color: black;
  padding-bottom: 10px;
}
div#mc_embed_signup {
  background:#fff;
  clear:left;
  padding: 2rem;
  font:0.95rem Helvetica,Arial,sans-serif;
  .mc-field-group {
    width: 80%;
    margin: auto;
  }
}

div.mce_inline_error {
  padding-bottom: 0!important;
  margin-bottom: 0!important;
}
form#mc-embedded-subscribe-form {
  display: inline;
  width: auto;
  margin-right: 10px;
  .mc_fieldset {
    border:none;
    min-height: 0px;
    padding-bottom:0px;
  }
}
.button-subscribe {
  background-color: $primary !important;
}
