@import '~bulma/sass/utilities/initial-variables';

// Variables
$gap: 32px;
$desktop: 1000px + (2 * $gap);
$widescreen-enabled: false;
$fullhd-enabled: false;
$section-padding: 1.5rem 1rem;
$section-padding-medium: 5rem 2rem;
$tablet: 500px;
$fullhd: 1500px;


// Colors
$dark: #333333;
$primary: #008FDF;

$text-color: $dark;
$text-light-color: #787878;
$text-color-2: #A0A0A0;
$text-color-3: #646464;
$link: #333333;
$text-highlight-color: #1EC09C;
$dark-color: #000;
$white: #FFF;
$grey-400: #ced4da !default;

$link: $primary;
$text-highlight-color: #c4536a;

$block-primary-color: #f5f5f5;

$block-secondary-color: #FFF;
$gradient-left-color: #008FDF;
$gradient-left-color2: #008FDF;
$gradient-left-color3: #008FDF;
$gradient-right-color: #99f188;
$gradient-right-color2: #40ebab;
$gradient-right-color3: #99f188;
$gradient-1: linear-gradient(90deg, $gradient-left-color, $gradient-right-color);


// Fonts
$size-1: 3.75rem;
$size-2: 3.125rem;
$size-3: 2.5rem;
$size-4: 1.875rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.875rem;
$system-font-family: Basetica,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
$primary-font-family: $system-font-family;
$strong-weight: 500;


// Headings
$title-color: $text-color;
$title-weight: 200;
$title-line-height: 1.2;
$title-strong-weight: 400;
$subtitle-color: $text-color;
$subtitle-weight: 200;
$subtitle-line-height: 1.3;
$subtitle-negative-margin: -1rem;


// Transition
$transition-time: 0.4s;


// Buttons
$button-height: 26px;
$button-width: 35px;
$button-color: $white;
$button-disabled-background-color: $grey;


// Navbar
$navbar-height: 3.25rem !default;
$navbar-box-shadow-size: 0;
$navbar-item-hover-color : $primary;
$navbar-item-hover-background-color : transparent;
$navbar-tab-active-color : $primary;
$navbar-breakpoint: $desktop;
$navbar-background-color: $grey-lightest;
$navbar-item-img-max-height: 2rem;
$navbar-animation-duration: 0.5s;
$navbar-item-animation-delay: 0.05s;

//fis lib:
//fis-included-countries controls whichfis are used - see docs

// Top Banner Sticky
$banner-top-sticky-height: $navbar-height;


// Main content
$main-offset-top: calc($navbar-height + $banner-top-sticky-height);


// flag-icons
// $flag-included-countries controls which flags are used - see docs
// $countries is set by us in webpack config,
$flag-icons-path: '~flag-icons/flags';
$flag-icons-included-countries: $countries;


// Buttons
$button-color: $white;
$button-disabled-background-color: $grey;
